import { securedWrap } from '@mop/shared/utils/securedWrap';
import { isClient, addValuesToArray } from '@mop/shared/utils/util';
import { sessionStorageGet, sessionStorageSet } from '@mop/shared/utils/sessionStorage';
import { localStorageGet, localStorageSet } from '@mop/shared/utils/localStorage';
import type { CmsStoryModel } from '@/types/cms';
import type { CartCheeringMessageType } from '@/types';

let cmsStoryModel: CmsStoryModel | null = null;

type StorageType = 'local' | 'session';
type MessageStorage = {
  NAME: string;
  TYPE: StorageType;
};

const STORAGE_NAME: string = constants.SESSION_STORAGE.CHEERING_MESSAGES_SEEN;
const MESSAGE_STORAGES: { [key: string]: MessageStorage } = {
  WELCOME: {
    NAME: 'welcome',
    TYPE: 'local',
  },
  FILTER: {
    NAME: 'filter',
    TYPE: 'session',
  },
  FILTER_USED: {
    NAME: 'filter-used',
    TYPE: 'session',
  },
  WISHLIST_HEADER: {
    NAME: 'wishlist-header',
    TYPE: 'session',
  },
};

const STORYBLOK_ATTRIBUTES: { [key: string]: string } = {
  WELCOME: 'welcomeMessages',
  FILTER: 'filterMessages',
  FILTER_USED: 'filterUsedMessages',
  PRODUCT_TILE_WISHLIST: 'productTileWishlistMessages',
  CART_OVERLAY: 'cartOverlayMessages',
  CART_OVERLAY_SHOP_THE_LOOK: 'cartOverlayShopTheLookMessages',
  WISHLIST_HEADER: 'wishlistHeaderMessages',
  CART_SALE: 'cartSaleMessages',
  CART_NEW: 'cartNewMessages',
  CART_GUEST: 'cartGuestMessages',
  CART: 'cartMessages',
};

export default function useMopCheeringsMessage() {
  const { $breakpoint, $mopConfig, $mopI18n } = useNuxtApp();

  function getStorageValue(storageType: StorageType): string[] {
    try {
      return (
        JSON.parse(
          storageType === 'local' ? localStorageGet(STORAGE_NAME) ?? '[]' : sessionStorageGet(STORAGE_NAME) ?? '[]',
        ) ?? []
      );
    } catch (error) {
      return [];
    }
  }

  function setStorageValue(value: any, storageType: StorageType) {
    value = JSON.stringify(value);
    storageType === 'local' ? localStorageSet(STORAGE_NAME, value) : sessionStorageSet(STORAGE_NAME, value);
  }

  function getCheeringMessageSeen(messageStorage: MessageStorage) {
    const cheeringValues: string[] = getStorageValue(messageStorage.TYPE);
    return cheeringValues.includes(messageStorage.NAME);
  }

  function setCheeringMessageSeen(messageStorage: MessageStorage) {
    const cheeringValues: string[] = getStorageValue(messageStorage.TYPE);
    addValuesToArray(cheeringValues, messageStorage.NAME);
    setStorageValue(cheeringValues, messageStorage.TYPE);
  }

  function canShowCheeringMessage() {
    return isClient && $breakpoint.isMobileRef.value && $mopConfig.isCheeringMessagesEnabled();
  }

  async function fetchCheeringMessageStory() {
    const { getCmsStory, cmsStoryModelRef } = useMopCms('cheering', true);
    await getCmsStory('shop/cheeringmessages');
    return cmsStoryModelRef.value;
  }

  async function getCheeringMessage(attributeName: string): Promise<string> {
    if (!isClient || !$breakpoint.isMobileRef.value) {
      return '';
    }
    if (!cmsStoryModel) {
      cmsStoryModel = await fetchCheeringMessageStory();
    }
    if (cmsStoryModel?.hasError()) {
      return '';
    }
    const messages: string[] = cmsStoryModel?.getAttribute(attributeName)?.map((message: any) => message.text) ?? [];
    const messagesLength: number = messages.length;
    if (!messagesLength) {
      return '';
    }
    return messages[Math.floor(Math.random() * messages.length)];
  }

  async function getWelcomeCheeringMessage() {
    return await getCheeringMessage(STORYBLOK_ATTRIBUTES.WELCOME);
  }

  async function getFilterCheeringMessage() {
    return await getCheeringMessage(STORYBLOK_ATTRIBUTES.FILTER);
  }

  async function getFilterUsedCheeringMessage() {
    return await getCheeringMessage(STORYBLOK_ATTRIBUTES.FILTER_USED);
  }

  async function getProductTileWishlistCheeringMessage() {
    return await getCheeringMessage(STORYBLOK_ATTRIBUTES.PRODUCT_TILE_WISHLIST);
  }

  async function getCartOverlayCheeringMessage(showShopTheLookMessage = false) {
    const attributeName: string = showShopTheLookMessage
      ? STORYBLOK_ATTRIBUTES.CART_OVERLAY_SHOP_THE_LOOK
      : STORYBLOK_ATTRIBUTES.CART_OVERLAY;
    return await getCheeringMessage(attributeName);
  }

  async function getWishlistHeaderCheeringMessage() {
    return await getCheeringMessage(STORYBLOK_ATTRIBUTES.WISHLIST_HEADER);
  }

  async function getCartCheeringMessage(type?: CartCheeringMessageType, isGuest = false, discount?: number) {
    let attributeName: string = STORYBLOK_ATTRIBUTES.CART;
    if (type === 'sale') {
      attributeName = STORYBLOK_ATTRIBUTES.CART_SALE;
    } else if (type === 'new') {
      attributeName = STORYBLOK_ATTRIBUTES.CART_NEW;
    } else if (isGuest) {
      attributeName = STORYBLOK_ATTRIBUTES.CART_GUEST;
    }
    let message: string = await getCheeringMessage(attributeName);
    if (discount) {
      const formatdedDiscount: string = $mopI18n.formatPrice({
        price: Math.abs(discount),
        currency: $mopI18n.currency,
      });
      message = message.replace('{DISCOUNT}', formatdedDiscount);
    }
    return message;
  }

  function showWelcomeCheeringMessage() {
    const showCheeringMessage: boolean = canShowCheeringMessage() && !getCheeringMessageSeen(MESSAGE_STORAGES.WELCOME);
    if (showCheeringMessage) {
      setCheeringMessageSeen(MESSAGE_STORAGES.WELCOME);
    }
    return showCheeringMessage;
  }

  function showFilterCheeringMessage() {
    const showCheeringMessage: boolean = canShowCheeringMessage() && !getCheeringMessageSeen(MESSAGE_STORAGES.FILTER);
    if (showCheeringMessage) {
      setCheeringMessageSeen(MESSAGE_STORAGES.FILTER);
    }
    return showCheeringMessage;
  }

  function showFilterUsedCheeringMessage() {
    const showCheeringMessage: boolean =
      canShowCheeringMessage() && !getCheeringMessageSeen(MESSAGE_STORAGES.FILTER_USED);
    if (showCheeringMessage) {
      setCheeringMessageSeen(MESSAGE_STORAGES.FILTER_USED);
    }
    return showCheeringMessage;
  }

  function showProductTileWishlistCheeringMessage() {
    return canShowCheeringMessage();
  }

  function showCartOverlayCheeringMessage() {
    return canShowCheeringMessage();
  }

  function showWishlistHeaderCheeringMessage(activeTab: string, cartCount: number) {
    const showWishlistCheeringMessage: boolean =
      canShowCheeringMessage() &&
      activeTab === `/${URLS.CART}` &&
      !getCheeringMessageSeen(MESSAGE_STORAGES.WISHLIST_HEADER) &&
      cartCount > 0;
    if (showWishlistCheeringMessage) {
      setCheeringMessageSeen(MESSAGE_STORAGES.WISHLIST_HEADER);
    }
    return showWishlistCheeringMessage;
  }

  function showCartCheeringMessage() {
    return canShowCheeringMessage();
  }

  return securedWrap({
    getWelcomeCheeringMessage,
    getFilterCheeringMessage,
    getFilterUsedCheeringMessage,
    getProductTileWishlistCheeringMessage,
    getCartOverlayCheeringMessage,
    getCartCheeringMessage,
    getWishlistHeaderCheeringMessage,
    showWelcomeCheeringMessage,
    showFilterCheeringMessage,
    showFilterUsedCheeringMessage,
    showProductTileWishlistCheeringMessage,
    showCartOverlayCheeringMessage,
    showWishlistHeaderCheeringMessage,
    showCartCheeringMessage,
  });
}
